import { useEffect } from 'react';
import axios from 'axios';

import Friend from '../components/HomeLeft/Friend';
import Logout from '../components/HomeLeft/Logout';

const Left = (props) => {
	useEffect(async () => {
		const getFriends = async () => {
			const response = await axios.get(`${props.apiUsersUrl}/get-friends`);
			props.setAllUsers(response.data);
		};
		getFriends();
	}, []);

	return (
		<section className="Left">
			<header className="discussions">
				<img
					src="https://pbs.twimg.com/profile_images/923557898218889216/g4BH7Arj.jpg"
					alt="profile image"
				></img>
				<h1>Discussions</h1>
				<Logout setUserLogged={props.setUserLogged} />
			</header>
			<div className="friends">
				{props.allUsers.map((friend) => {
					const friendIsNotLoggedUser = friend.id !== props.loggedUsersInfos.id;

					if (friendIsNotLoggedUser) {
						return (
							<Friend
								userName={`${friend.firstname} ${friend.lastname}`}
								setIdConversation={props.setIdConversation}
								id={friend.id}
								key={friend.id}
							/>
						);
					}
				})}
			</div>
		</section>
	);
};

export default Left;
