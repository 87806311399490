import { Link } from 'react-router-dom';

const Form = (props) => {
	return (
		<div className="Form">
			{props.errorMsg !== null && <p className="errorMsg">{props.errorMsg}</p>}

			{props.page === 'signup' && (
				<input
					type="text"
					name="lastname"
					placeholder="Nom"
					autoComplete="off"
				></input>
			)}
			{props.page === 'signup' && (
				<input
					type="text"
					name="firstname"
					placeholder="Prénom"
					autoComplete="off"
				></input>
			)}

			<input
				type="text"
				name="email"
				placeholder="Adresse-email ou mobile"
				autoComplete="off"
			></input>

			<input
				type="password"
				name="password"
				placeholder="Mot de passe"
				autoComplete="off"
			></input>
			<button type="submit">Continuer</button>
			<br />
			{props.page === 'login' && (
				<Link to="/signup">
					<small>Je n'ai pas de compte</small>
				</Link>
			)}
			{props.page === 'signup' && (
				<Link to="/login">
					<small>J'ai déjà un compte</small>
				</Link>
			)}
			<p className="form-footer">
				Ceci est une version non officielle de Messenger, faite par Camille
				Rakotoarisoa
			</p>
		</div>
	);
};

export default Form;
