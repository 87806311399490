import axios from 'axios';
import { useState, useEffect, useRef } from 'react';
import io from 'socket.io-client';

import Message from './Message';
import SendMessageBar from './SendMessageBar';

const Conversation = (props) => {
	const [conversation, setConversation] = useState([]);
	const socketRef = useRef();
	socketRef.current = io.connect(props.SERVER);

	useEffect(() => {
		getOneConversation();
		socketManage('receive message');
	}, [props.idConversation]);

	const getOneConversation = async (id = null) => {
		const conversationData = {};
		id === null
			? (conversationData.id = props.idConversation)
			: (conversationData.id = id);

		const response = await axios.post(
			`${props.apiConversationUrl}/get-one-conversation`,
			conversationData
		);
		setConversation(response.data);
	};

	const sendMessage = async (e) => {
		e.preventDefault();

		const conversationData = {
			id_sender: props.loggedUsersInfos.id,
			id_receiver: props.idConversation,
			message: e.target['message'].value,
		};

		if (conversationData.message !== '') {
			await axios.post(
				`${props.apiConversationUrl}/send-message`,
				conversationData
			);
			getOneConversation();
			socketManage('emit message', conversationData.id_receiver);
			e.target['message'].value = '';
		}
	};

	const socketManage = (action, message_id = null) => {
		if (action === 'emit message') {
			socketRef.current.emit('message id', message_id);
		} else if (action === 'receive message') {
			socketRef.current.on('message id', (id) => getOneConversation(id));
			return () => socketRef.current.disconnect();
		}
	};

	return (
		<div className="Conversation">
			{conversation.map((message) => (
				<Message
					message={message}
					idConversation={props.idConversation}
					loggedUsersInfos={props.loggedUsersInfos}
					key={message.id}
				/>
			))}

			<br />
			<br />
			<br />

			<SendMessageBar
				idConversation={props.idConversation}
				apiConversationUrl={props.apiConversationUrl}
				sendMessage={sendMessage}
			/>
		</div>
	);
};

export default Conversation;
