const Logout = (props) => {
	const Logout = () => {
		localStorage.clear();
		props.setUserLogged(false);
	};

	return (
		<span className="Logout" onClick={Logout}>
			Se déconnecter
		</span>
	);
};

export default Logout;
