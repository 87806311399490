const Message = (props) => {
	if (
		props.message.id_sender === props.idConversation &&
		props.message.id_receiver === props.loggedUsersInfos.id
	) {
		return (
			<div
				className="Message MessageReceived"
				dangerouslySetInnerHTML={{ __html: props.message.message }}
			></div>
		);
	} else if (
		props.message.id_sender === props.loggedUsersInfos.id &&
		props.message.id_receiver === props.idConversation
	) {
		return (
			<div
				className="Message MessageSended"
				dangerouslySetInnerHTML={{ __html: props.message.message }}
			></div>
		);
	} else {
		return null;
	}
};
export default Message;
