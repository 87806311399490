import { useState, useEffect } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import Login from './pages/Login';
import Signup from './pages/Signup';
import NotFound from './pages/NotFound';
import Home from './pages/Home';

const App = () => {
	const SERVER = 'https://apis.camillerakoto.fr/messenger';
	const apiUsersUrl = `${SERVER}/user`;
	const apiConversationUrl = `${SERVER}/conversation`;

	const [userLogged, setUserLogged] = useState(false);
	const [loggedUsersInfos, setLoggedUsersInfos] = useState(null);
	const [allUsers, setAllUsers] = useState([]);
	const [idConversation, setIdConversation] = useState(null);

	useEffect(() => authUserAutomatically(), [userLogged]);

	const authUserAutomatically = () => {
		const savedUserLoggedStatus = JSON.parse(
			localStorage.getItem('messenger-userLogged')
		);
		const savedLoggedUsersInfos = JSON.parse(
			localStorage.getItem('messenger-loggedUsersInfos')
		);
		if (savedUserLoggedStatus !== null && savedUserLoggedStatus !== null) {
			setUserLogged(savedUserLoggedStatus);
			setLoggedUsersInfos(savedLoggedUsersInfos);
		}
	};

	return (
		<BrowserRouter>
			<Switch>
				<Route exact path="/home">
					{userLogged ? (
						<Home
							setUserLogged={setUserLogged}
							apiUsersUrl={apiUsersUrl}
							allUsers={allUsers}
							setAllUsers={setAllUsers}
							idConversation={idConversation}
							setIdConversation={setIdConversation}
							apiConversationUrl={apiConversationUrl}
							loggedUsersInfos={loggedUsersInfos}
							SERVER={SERVER}
						/>
					) : (
						<Redirect to="/login"></Redirect>
					)}
				</Route>
				<Route exact path="/login">
					{userLogged ? (
						<Redirect to="/home"></Redirect>
					) : (
						<Login
							apiUsersUrl={apiUsersUrl}
							setUserLogged={setUserLogged}
							setLoggedUsersInfos={setLoggedUsersInfos}
						/>
					)}
				</Route>
				<Route exact path="/signup">
					{userLogged ? (
						<Redirect to="/home"></Redirect>
					) : (
						<Signup
							apiUsersUrl={apiUsersUrl}
							setUserLogged={setUserLogged}
							setLoggedUsersInfos={setLoggedUsersInfos}
						/>
					)}
				</Route>
				<Route exact path="/">
					<Redirect to="/login"></Redirect>
				</Route>
				<Route component={NotFound}></Route>
			</Switch>
		</BrowserRouter>
	);
};

export default App;
