const SendMessageBar = (props) => {
	if (props.idConversation !== null) {
		return (
			<div className="SendMessageBar">
				<form onSubmit={props.sendMessage}>
					<input
						type="text"
						name="message"
						placeholder="Aa"
						autoComplete="off"
					></input>
					<button type="submit">
						<i className="fas fa-fighter-jet"></i>
					</button>
				</form>
			</div>
		);
	} else {
		return null;
	}
};

export default SendMessageBar;
