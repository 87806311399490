import Conversation from './HomeRight/Conversation';

const Right = (props) => {
	return (
		<section className="Right">
			<Conversation
				idConversation={props.idConversation}
				apiConversationUrl={props.apiConversationUrl}
				loggedUsersInfos={props.loggedUsersInfos}
				SERVER={props.SERVER}
			/>
		</section>
	);
};

export default Right;
