import Form from '../components/Form';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import axios from 'axios';

const Signup = (props) => {
	let history = useHistory();
	const [errorMsg, setErrorMsg] = useState(null);

	const signup = async (e) => {
		e.preventDefault();

		const enteredData = {
			firstname: e.target['firstname'].value,
			lastname: e.target['lastname'].value,
			email: e.target['email'].value,
			password: e.target['password'].value,
		};
		const allFieldsAreCompleted =
			enteredData.firstname !== '' &&
			enteredData.lastname !== '' &&
			enteredData.email !== '' &&
			enteredData.password !== '';

		if (allFieldsAreCompleted) {
			const response = await axios.post(
				`${props.apiUsersUrl}/signup`,
				enteredData
			);
			if (typeof response.data === 'object') {
				props.setUserLogged(true);
				localStorage.setItem('messenger-userLogged', JSON.stringify(true));

				props.setLoggedUsersInfos(response.data[0]);
				localStorage.setItem(
					'messenger-loggedUsersInfos',
					JSON.stringify(response.data[0])
				);

				history.push('/home');
			} else {
				setErrorMsg(response.data);
			}
		} else {
			setErrorMsg('Veuillez compléter tous les champs...');
		}
	};

	return (
		<section className="Signup">
			<img
				src="https://static.xx.fbcdn.net/rsrc.php/yd/r/hlvibnBVrEb.svg"
				alt="messenger"
			></img>
			<h1>Restez en contact avec vos personnes préférées</h1>
			<form onSubmit={signup}>
				<Form page="signup" errorMsg={errorMsg} />
			</form>
		</section>
	);
};

export default Signup;
