import Left from '../components/Left';
import Right from '../components/Right';

const Home = (props) => {
	return (
		<div className="Home">
			<Left
				apiUsersUrl={props.apiUsersUrl}
				allUsers={props.allUsers}
				setAllUsers={props.setAllUsers}
				setIdConversation={props.setIdConversation}
				loggedUsersInfos={props.loggedUsersInfos}
				setUserLogged={props.setUserLogged}
			/>
			<Right
				idConversation={props.idConversation}
				apiConversationUrl={props.apiConversationUrl}
				loggedUsersInfos={props.loggedUsersInfos}
				SERVER={props.SERVER}
			/>
		</div>
	);
};

export default Home;
