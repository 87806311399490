const Friend = (props) => {
	const changeIdConversation = (id) => {
		props.setIdConversation(id);
	};

	return (
		<div className="Friend" onClick={() => changeIdConversation(props.id)}>
			<div className="friend-image">
				<img
					src="https://icon-library.com/images/bot-icon/bot-icon-5.jpg"
					alt="friend"
				></img>
			</div>
			<div className="friend-infos">
				<p
					className="friend-name"
					dangerouslySetInnerHTML={{ __html: props.userName }}
				></p>
				<p className="friend-message">Envoyer un message</p>
			</div>
		</div>
	);
};

export default Friend;
